import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ErrorOption, FieldName, useForm } from 'react-hook-form'
import { errors } from 'faunadb'
import { NextRouter, useRouter } from 'next/router'
import { getClientLogger } from '../services/logs.client'
import { validateEmail } from '../lib/formHelper'
import { useUserSession } from '../services/session'
import { ContentTemplate } from '../components/ContentTemplate'
import { Body, Title, TitleLevel } from '../components/Typography'
import { Feedback, FeedbackType, Form, Input, Label, Submit } from '../components/Form'
import styles from './login.module.scss'

interface FormInput {
  email: string
}

const INVALID_EMAIL = 'Login Error: Invalid Email.'

const genericErrorMessage = 'Unable to Login at the moment. Please, try again in a few moments.'
const formSubmitHandler = (
  setErrorMessage: (email: FieldName<FormInput>, error: ErrorOption) => void,
  setFormState: Dispatch<SetStateAction<FormState>>,
  setButtonInfo: Dispatch<SetStateAction<string>>,
  router: NextRouter
) => async ({ email }: FormInput): Promise<void> => {
  try {
    setFormState(FormState.LOADING)
    setButtonInfo('Loggin in...')
    if (!validateEmail(email)) {
      throw new Error(INVALID_EMAIL)
    }
    const { sendAuthLink, AuthSuccess } = await import('../services/auth.client')
    const [authTry, maybeDidToken] = await sendAuthLink(email, setButtonInfo)
    if (authTry === AuthSuccess.EMAIL_SENT) {
      setFormState(FormState.SUCCESS)
      setButtonInfo('Done')
      return
    }
    if (authTry === AuthSuccess.ALREADY_LOGGED_IN && maybeDidToken != null) {
      router.push(`/app/welcome?did_token=${maybeDidToken}`).catch((error) => {
        getClientLogger().captureException(error)
        setErrorMessage('email', { message: 'You\'re already logged in.' })
      })
      return
    }
    setButtonInfo('Try again')
    setFormState(FormState.FAIL)
    return setErrorMessage('email', { message: genericErrorMessage })
  } catch (error) {
    getClientLogger().captureException(error)
    setFormState(FormState.FAIL)
    setButtonInfo('Try again')
    if (error.message === INVALID_EMAIL) {
      return setErrorMessage('email', { message: 'Your email seems to be invalid. Please, provide a valid one.' })
    }
    if (error instanceof errors.NotFound || error.message === 'Unable to check if user is on the platform') {
      return setErrorMessage('email', { message: 'It seems you are not part of our platform yet. Check the information bellow.' })
    }
    setErrorMessage('email', { message: genericErrorMessage })
  }
}

const FormModal: React.FC<{ email: string, formState: FormState }> = ({ email, formState }) => {
  if (formState !== FormState.SUCCESS) {
    return null
  }
  return (
    <div className={styles.loginFormOverlay}>
      <Body><strong>Check your email</strong></Body>
      <Body>We emailed a magic link to <strong>{email}</strong>. Click the link to log in.</Body>
    </div>
  )
}

const AlreadyLoggedInModal: React.FC = () => (
  <div className={styles.loginFormOverlay}>
    <Body><strong>You're already logged in</strong></Body>
    <Body>You'll be redirected in a moment...</Body>
  </div>
)

enum FormState {
  SUCCESS,
  FAIL,
  LOADING,
  INITIAL
}
export default function Login (): JSX.Element {
  const { user } = useUserSession()
  const router = useRouter()
  const [formState, setFormState] = useState<FormState>(FormState.INITIAL)
  const [buttonInfo, setButtonInfo] = useState<string>('Sign in')
  const { register, handleSubmit, errors, setError, watch } = useForm<FormInput>()
  const submit = formSubmitHandler(setError, setFormState, setButtonInfo, router)
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    if (user != null) {
      timeout = setTimeout(() => {
        router.push('/app').catch(error => getClientLogger().captureException(error))
      }, 3000)
    }
    return () => {
      if (timeout != null) {
        clearTimeout(timeout)
      }
    }
  }, [user])

  const inputFeedback = errors.email?.message
  return (
    <ContentTemplate pageTitle={'Login'}>
      <Title level={TitleLevel.ONE}>Login</Title>
      <Body>Enter your email bellow to receive a login link.</Body>
      <Form onSubmit={handleSubmit(submit)}>
        {user != null && <AlreadyLoggedInModal />}
        {formState === FormState.SUCCESS && <FormModal formState={formState} email={watch('email')} />}
        <Label htmlFor={'email'}>Email:</Label>
        <Input ref={register({ required: true })} id="email" type="email" name="email" placeholder="email@letters.group" required />
        <Feedback type={inputFeedback != null ? FeedbackType.FAIL : FeedbackType.INFO}>{inputFeedback}</Feedback>
        <Submit value={buttonInfo} />
      </Form>
      <Body>Letters is in its early infancy, so only a few invited users can join the platform at this point.</Body>
    </ContentTemplate>
  )
}
